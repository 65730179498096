import PropTypes from 'prop-types'
import Typography from "@mui/material/Typography";
import {Datagrid, FunctionField, List, TextInput} from "react-admin";
import Resources from "../../Resources";
import Checkbox from "@mui/material/Checkbox";
import {PartyField} from "../controls/PartyField";
import React from "react";
import CustomListEmpty from "../controls/CustomListEmpty";

function SelectNewCreditorsGrid(props) {

    function onCreditorClick(id) {
        if(props.creditorIds.includes(id)) {
            props.setCreditorIds([...props.creditorIds.filter((val) => val !== id)])
        } else {
            props.setCreditorIds([...props.creditorIds, id]);
        }
        return false;
    }

    function applyIfPresent (obj, propName) {
        let value = props[propName]
        if (value) {
            obj[propName] = value
        }
    }

    const creditorFilters = {
        loanApplicationId: props.loanApplicationId,
        exceptExisting: !!props.exceptExisting,
        borrowerType: props.borrowerType,
        dealType: props.dealType,
        sector: props.sector,
        country: props.country,
        loanAmount: props.loanAmount,
        loanType: props.loanType,
        loanCurrency: props.loanCurrency,
        region: props.region,
        loanTerm: props.loanTerm,
        showAll: !!props.showAll,
    };
    if(props.loanIrrTo && props.loanIrrTo > 0) {
        creditorFilters.loanIrrTo = props.loanIrrTo;
    }
    if(props.loanIrmmTo && props.loanIrmmTo > 0) {
        creditorFilters.loanIrmmTo = props.loanIrmmTo;
    }
    applyIfPresent(creditorFilters, "revenue")
    applyIfPresent(creditorFilters, "ebitda")
    applyIfPresent(creditorFilters, "enterpriseValue")
    applyIfPresent(creditorFilters, "loanStructure")
    applyIfPresent(creditorFilters, "businessStage")
    applyIfPresent(creditorFilters, "useOfFunds")
    applyIfPresent(creditorFilters, "revenue")

    const filterByCompanyName = [
        <TextInput source="fullCompanyName" label="Organisation" resettable alwaysOn sx={{width: "400px"}}/>,
    ];

    return (
        <>
            <Typography fontWeight="bold" color={props.creditorIds.length === 0 ? "red" : ""}> Selected {props.creditorIds.length} Debt Investors.</Typography>
            <List filter={creditorFilters} actions={false} disableSyncWithLocation={true}
                  resource={Resources.CREDITORS.name + "/" + Resources.CREDITORS.actions.FIND_APPLICABLE_FOR_LOAN_APPLICATION.name}
                  filters={filterByCompanyName}
                  empty={<CustomListEmpty/>}
            >
                <Datagrid bulkActionButtons={false} rowClick={onCreditorClick}>
                    <FunctionField render={(record) => {
                        return (
                            <Checkbox size="small" checked={props.creditorIds.includes(record.id)} />
                        );
                    }} />
                    <PartyField source="id" partyTypeSource="partyType" label="Debt Investor" />

                </Datagrid>
            </List>

        </>
    );
}

export default SelectNewCreditorsGrid;

SelectNewCreditorsGrid.propTypes = {
  borrowerType: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  creditorIds: PropTypes.array.isRequired,
  exceptExisting: PropTypes.bool,
  loanAmount: PropTypes.number.isRequired,
  loanApplicationId: PropTypes.number.isRequired,
  loanCurrency: PropTypes.string.isRequired,
  loanIrrTo: PropTypes.number,
  loanType: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  setCreditorIds: PropTypes.func.isRequired
}