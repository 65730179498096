import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {convertArrayToObject, onError, toISODate} from "../../common/utils";
import Grid from "@mui/material/Grid";
import {DateInput, Form, SaveButton, useDataProvider, useNotify} from "react-admin";
import {CurrencyInput} from "../controls/CurrencyInput";
import {CurrencyType} from "../dicts/ExchangeEnums";
import DictInput from "../controls/DictInput";
import Resources from "../../Resources";
import {DebtInvestorsBySmth} from "./DebtInvestorsBySmth";
import getCountries from "../../common/countries";
import {BEGIN_DATE_1_AUG_2023} from "../../common/constants";

const NAObj = {id: "-", code: "-", name: "N/A"};

export const DebtInvestorsSummary = ({ filters, onFiltersChange }) => {
    //todo use loading
    const [loading, setLoading] = useState(false)
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [aumFrom, setAumFrom] = useState(0)
    const [aumTo, setAumTo] = useState(0)
    // dicts
    const [businessCategories, setBusinessCategories] = useState({})
    const [originationSources, setOriginationSources] = useState({})
    const [aumDict, setAumDict] = useState({})
    const [dataByCategory, setDataByCategory] = useState(null);
    const [dataByChannel, setDataByChannel] = useState(null);
    const [dataByAum, setDataByAum] = useState(null);
    const [dataByCountry, setDataByCountry] = useState(null);

    const defaultFilters = useMemo(
        () => ({currency: "USD", relationshipStartDateFrom: BEGIN_DATE_1_AUG_2023, relationshipStartDateTo: new Date()}),
        []
    )

    const getCountriesAsMapOfCodeNameWithNA = () => {
        const initialValue = {};
        const obj = getCountries().reduce((obj, item) => {
            return {
                ...obj,
                [item[0]]: {id: item[0], code: item[0], name: item[1]},
            };
        }, initialValue);
        obj["-"] = NAObj;
        return obj
    }
    const countries = getCountriesAsMapOfCodeNameWithNA()

    const onDebtInvestorsFilterSubmit = useCallback(
        (values) => {
            onFiltersChange({...values, aumFrom, aumTo})
        },
        [onFiltersChange, aumFrom, aumTo]
    );

    const validate = (form) => {
        const errors = {};
        if (form.assetsUnderMgmt && !form.currency) {
            errors.currency = "Currency is required";
        }
        if (!form.relationshipStartDateFrom) {
            errors.relationshipStartDateFrom = "Relationship Start Date (From) is required";
        }
        if (!form.relationshipStartDateTo) {
            errors.relationshipStartDateTo = "Relationship Start Date (To) is required";
        }
        return errors;
    }

    const loadBusinessCategories = () => {
        setLoading(true)
        dataProvider.getList(Resources.DICT_BUSINESS_CATEGORIES.name,
            {sort: {field: 'sortOrder', order: 'ASC'}, filter: {includeDeleted: true}})
            .then(({data}) => {
                // console.log("bc", data)
                const bcMap = convertArrayToObject(data, "id")
                bcMap["-"] = NAObj
                // console.log("bcMap", bcMap)
                setBusinessCategories(bcMap)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                onError(err, notify)
            });
    }

    const loadOriginalSources = () => {
        setLoading(true)
        dataProvider.getList(Resources.DICT_ORIGINATION_SOURCES.name,
            {sort: {field: 'sortOrder', order: 'ASC'}, filter: {includeDeleted: true}})
            .then(({data}) => {
                // console.log("bc", data)
                const bcMap = convertArrayToObject(data, "id")
                bcMap["-"] = NAObj
                // console.log("bcMap", bcMap)
                setOriginationSources(bcMap)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                onError(err, notify)
            });
    }

    const loadAumDict = () => {
        setLoading(true)
        dataProvider.getList(Resources.DICT_ASSETS_UNDER_MGMT.name,
            {sort: {field: 'sortOrder', order: 'ASC'}, filter: {includeDeleted: true}})
            .then(({data}) => {
                // console.log("bc", data)
                const bcMap = convertArrayToObject(data, "id")
                bcMap["-"] = NAObj
                // console.log("bcMap", bcMap)
                setAumDict(bcMap)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                onError(err, notify)
            });
    }

    useEffect(() => {
        loadBusinessCategories()
        loadOriginalSources()
        loadAumDict()
    }, [])

    function loadData(filterValues) {
        loadDebtInvestorsSummaryBySmth(
            Resources.DASHBOARD.DEBT_INVESTORS_MONTHLY_INCREASE_BY_CATEGORY.name, setDataByCategory,
            filterValues.currency, filterValues.relationshipStartDateFrom, filterValues.relationshipStartDateTo,
            filterValues.aumFrom, filterValues.aumTo
        )
        loadDebtInvestorsSummaryBySmth(
            Resources.DASHBOARD.DEBT_INVESTORS_MONTHLY_INCREASE_BY_CHANNEL.name, setDataByChannel,
            filterValues.currency, filterValues.relationshipStartDateFrom, filterValues.relationshipStartDateTo,
            filterValues.aumFrom, filterValues.aumTo
        )
        loadDebtInvestorsSummaryBySmth(
            Resources.DASHBOARD.DEBT_INVESTORS_MONTHLY_INCREASE_BY_AUM.name, setDataByAum,
            filterValues.currency, filterValues.relationshipStartDateFrom, filterValues.relationshipStartDateTo,
            filterValues.aumFrom, filterValues.aumTo
        )
        loadDebtInvestorsSummaryBySmth(
            Resources.DASHBOARD.DEBT_INVESTORS_MONTHLY_INCREASE_BY_COUNTRY.name, setDataByCountry,
            filterValues.currency, filterValues.relationshipStartDateFrom, filterValues.relationshipStartDateTo,
            filterValues.aumFrom, filterValues.aumTo
        )
    }

    useEffect(() => {
        if (filters) {
            loadData(filters);
        }
    }, [filters]);

    function loadDebtInvestorsSummaryBySmth (res, setDataFn, currency,
                                             relationshipStartDateFrom, relationshipStartDateTo, aumFrom, aumTo) {
        // setLoading(true)
        let q = "";
        if (currency) {
            q += `currency=${currency}`
        }
        if (relationshipStartDateFrom) {
            q += `&relationshipStartDateFrom=${toISODate(relationshipStartDateFrom)}`
        }
        if (relationshipStartDateTo) {
            q += `&relationshipStartDateTo=${toISODate(relationshipStartDateTo)}`
        }
        if (aumFrom) {
            q += `&aumFrom=${aumFrom}`
        }
        if (aumTo) {
            q += `&aumTo=${aumTo}`
        }

        dataProvider.doAction(res, {
            method: 'GET',
            queryStr: q
        })
            .then(({data}) => {
                // console.log("data", data);
                setDataFn(data.data)
                // setLoading(false)
            })
            .catch((err) => {
                setDataFn(null)
                // setLoading(false)
                onError(err, notify)
            });
    }

    const DebtInvestorsFilterForm = (props) => {
        const onAumChange = (event, values) => {
            const v = event.target.value;
            const dict = values.find((e) => e.id === v);
            if (dict) {
                setAumFrom(dict.amountFromIncl);
                setAumTo(dict.amountToExcl);
            } else {
                setAumFrom(null)
                setAumTo(null)
            }
        }

        return (
            <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={2}>
                    <CurrencyInput source="currency"
                                   currencyTypeCode={CurrencyType.FIAT.code}
                                   label="Currency"
                    />
                </Grid>
                <Grid item xs={2}>
                    <DictInput source="assetsUnderMgmt"
                               reference={Resources.DICT_ASSETS_UNDER_MGMT.name}
                               fullWidth
                               optionValue="id"
                               onChange={onAumChange}
                    />
                </Grid>
                <Grid item xs={2}>
                    <DateInput label="Relationship Start Date (from)" source="relationshipStartDateFrom" required/>
                </Grid>
                <Grid item xs={2}>
                    <DateInput label="Relationship Start Date (to)" source="relationshipStartDateTo" required/>
                </Grid>
                <Grid item xs={1}>
                    <SaveButton alwaysEnable color="primary" size="large" variant="contained" sx={{marginTop: "10px"}}
                                label="Apply"/>
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {
        onFiltersChange(defaultFilters)
    }, []);

    return (
        <Form defaultValues={defaultFilters}
              onSubmit={onDebtInvestorsFilterSubmit}
              validate={validate}>
            <Grid container columnSpacing={1} rowSpacing={1} className="dashboard-grid">
                <Grid item xs={11}>
                    <DebtInvestorsFilterForm/>
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth data={dataByCategory}
                                         dict={businessCategories}
                                         label="Monthly Increase by Category"
                    />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth data={dataByCategory}
                                         dict={businessCategories}
                                         label="Monthly Growth by Category (Cumulative)"
                    />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth data={dataByChannel}
                                         dict={originationSources}
                                         label="Monthly Increase by Channel"
                    />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth data={dataByChannel}
                                         dict={originationSources}
                                         label="Monthly Growth by Channel (Cumulative)"
                    />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth data={dataByAum}
                                         dict={aumDict}
                                         label="Monthly Increase by Aum"
                    />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth data={dataByAum}
                                         dict={aumDict}
                                         label="Monthly Increase by Aum (Cumulative)"
                    />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth data={dataByCountry}
                                         dict={countries}
                                         label="Monthly Increase by Country"
                    />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth data={dataByCountry}
                                         dict={countries}
                                         label="Monthly Increase by Country (Cumulative)"
                    />
                </Grid>
            </Grid>
        </Form>
    )
}