import * as React from "react";
import {useState} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {LoanApplicationsSummary} from "./view/dashboard/LoanApplicationsSummary";
import {DebtInvestorsSummary} from "./view/dashboard/DebtInvestorsSummary";
import {Button, Toolbar, useDataProvider, useNotify, useTheme} from "react-admin";
import {LoanApplicationsSummaryNew} from "./view/dashboard/LoanApplicationsSummaryNew";
import Resources from "./Resources";
import {getFileNameWithDateTime, toISODate} from "./common/utils";
import {useLoanApplicationSummaryFilters} from "./view/dashboard/useLoanApplicationSummaryFilters";

export const Dashboard = () => {
    const [theme] = useTheme();
    const data = useLoanApplicationSummaryFilters();

    const dataProvider = useDataProvider();
    const [exporting, setExporting] = useState(false)
    const notify = useNotify();

    function convertToFormData(v) {
        return {
            lasCurrency: v.las.currency,
            lasCreatedDateFrom: toISODate(v.las.createdDateFrom),
            lasCreatedDateTo: toISODate(v.las.createdDateTo),
            ladCurrency: v.lad.currency,
            ladCreatedDateFrom: toISODate(v.lad.createdDateFrom),
            ladCreatedDateTo: toISODate(v.lad.createdDateTo),
            disCurrency: v.dis.currency,
            disOperatedDateFrom: toISODate(v.dis.relationshipStartDateFrom),
            disOperatedDateTo: toISODate(v.dis.relationshipStartDateTo),
            disAumFrom: v.dis.aumFrom,
            disAumTo: v.dis.aumTo,
        }
    }

    async function exportToExcel() {
        setExporting(true);
        const response = await fetch(`/api/v1/${Resources.DASHBOARD.EXPORT.name}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(convertToFormData(data.filters)),
        });

        const blob = await response.blob(); // create a new Blob object.
        const url = window.URL.createObjectURL(blob); // create a new object url
        const a = document.createElement("a"); // create a new anchor element
        a.href = url; // set its href to the object URL
        a.download = getFileNameWithDateTime("dashboard", ".xlsx");
        a.click();
        setExporting(false);
    }

    return (
        <div className={`dashboard dashboard-${theme}`}>
            <Toolbar className="manual-toolbar">
                <Button color="primary" onClick={exportToExcel} disabled={exporting}>Export to Excel</Button>
            </Toolbar>

            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    Loan Applications Summary
                </AccordionSummary>
                <AccordionDetails>
                    <LoanApplicationsSummary filters={data.filters.las}
                                             onFiltersChange={(las) => data.onFiltersChange({las})}/>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    Loan Applications Summary (NEW)
                </AccordionSummary>
                <AccordionDetails>
                    <LoanApplicationsSummaryNew filters={data.filters.lad}
                                                onFiltersChange={(lad) => data.onFiltersChange({lad})}/>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    Debt Investors Summary
                </AccordionSummary>
                <AccordionDetails>
                    <DebtInvestorsSummary filters={data.filters.dis}
                                          onFiltersChange={(dis) => data.onFiltersChange({dis})}/>
                </AccordionDetails>
            </Accordion>
{/*            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    Legal Entities Summary
                </AccordionSummary>
                <AccordionDetails>
                    <LegalEntitiesSummary/>
                </AccordionDetails>
            </Accordion>*/}
        </div>
    )
}
