import {
    BulkDeleteWithConfirmButton,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    List,
    SimpleForm,
    TextField,
    TextInput,
    usePermissions
} from "react-admin";
import {CheckPermission} from "../security/CheckPermission";
import {AdminPortalRoles} from "./Security";
import * as React from "react";
import {haveRole} from "../../common/utils";

const filtersByCode = [
    <TextInput label="Name" source="code" alwaysOn name="code" resettable/>,
]

export const EmailDomainsDictList = (props) => {
    const { permissions } = usePermissions();
    const deleteRole = AdminPortalRoles.BACKOFFICE_ADMIN;

    return (
        <List {...props} sort={{field: "code", order: "ASC"}} perPage={50} filters={filtersByCode} disableSyncWithLocation>
            <Datagrid rowClick={false} bulkActionButtons={haveRole(permissions, deleteRole)
                ? <BulkDeleteWithConfirmButton confirmContent="You will not be able to recover this records. Are you sure?"/>
                : false}>
                <TextField source="code" />
                <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
                    <DeleteWithConfirmButton/>
                </CheckPermission>
            </Datagrid>
        </List>
    );
}

export const EmailDomainsDictCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="code" name="code" required />
        </SimpleForm>
    </Create>
);