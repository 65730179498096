import {useCallback, useState} from "react";

export const useLoanApplicationSummaryFilters = () => {
    const [filters, setFilters] = useState({});

    const onFiltersChange = useCallback(
        (values) => setFilters(prevState => ({ ...prevState, ...values })),
        [setFilters]
    )

    return {filters, onFiltersChange };
}