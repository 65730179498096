import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {getFirstDayOfMonth, onError, toISODate} from "../../common/utils";
import Grid from "@mui/material/Grid";
import {DateInput, Form, SaveButton, useDataProvider, useNotify} from "react-admin";
import {CurrencyInput} from "../controls/CurrencyInput";
import {CurrencyType} from "../dicts/ExchangeEnums";
import Resources from "../../Resources";
import {MonthlyVolumeOfNewDeals} from "./MonthlyVolumeOfNewDeals";
import {MonthlyVolumeOfNewDealsByStatus} from "./MonthlyVolumeOfNewDealsByStatus";
import {BEGIN_DATE_1_AUG_2023} from "../../common/constants";

const LoanApplicationsFilterForm = () => {
    return (
        <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={3}>
                <CurrencyInput source="currency" label="Currency" required
                               currencyTypeCode={CurrencyType.FIAT.code}/>
            </Grid>
            <Grid item xs={3}>
                <DateInput label="Created Date (from)" source="createdDateFrom" required/>
            </Grid>
            <Grid item xs={3}>
                <DateInput label="Created Date (to)" source="createdDateTo" required/>
            </Grid>
            <Grid item xs={1}>
                <SaveButton alwaysEnable color="primary" size="large" variant="contained"
                            sx={{marginTop: "10px"}} label="Apply"/>
            </Grid>
        </Grid>
    )
}

export const LoanApplicationsSummaryNew = ({ filters, onFiltersChange }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loansDetailsByStatus, setLoansDetailsByStatus] = useState(null);
    const defaultFilters = useMemo(
        () => ({currency: "USD", createdDateFrom: BEGIN_DATE_1_AUG_2023, createdDateTo: new Date()}),
        []
    )

/*    const onLoanApplicationsFilterSubmit = useCallback(
        (values) => {
            loadLoanApplicationsDetailsByStatus(
                values.currency, values.createdDateFrom, values.createdDateTo
            )
        },
        [setFilterValues]
    );*/
    const validate = (form) => {
        const errors = {};
        if (!form.currency) {
            errors.currency = "Currency is required";
        }
        if (!form.createdDateFrom) {
            errors.createdDateFrom = "Created Date (From) is required";
        }
        if (!form.createdDateTo) {
            errors.createdDateTo = "Created Date (To) is required";
        }
        return errors;
    }

    useEffect(() => {
        console.log("LoanApplicationsSummaryNew.useEffect", filters)
        if (filters) {
            loadLoanApplicationsDetailsByStatus(
                filters.currency, filters.createdDateFrom, filters.createdDateTo
            )
        }
    }, [filters]);

    function loadLoanApplicationsDetailsByStatus (currency, createdDateFrom, createdDateTo) {
        // setLoading(true)
        dataProvider.doAction(Resources.DASHBOARD.LOAN_APPLICATIONS_DETAILS_BY_STATUS.name, {
            method: 'GET',
            queryStr: `currency=${currency}&createdDateFrom=${toISODate(createdDateFrom)}&createdDateTo=${toISODate(createdDateTo)}`
        })
            .then(({data}) => {
                // console.log("data", data);
                setLoansDetailsByStatus(data.data)
                // setLoading(false)
            })
            .catch((err) => {
                setLoansDetailsByStatus(null)
                // setLoading(false)
                onError(err, notify)
            });
    }

    useEffect(() => {
        onFiltersChange(defaultFilters)
    }, []);

    return (
        <Form defaultValues={defaultFilters}
              onSubmit={onFiltersChange}
              validate={validate}>
            <Grid container columnSpacing={1} rowSpacing={1} className="dashboard-grid">
                <Grid item xs={11}>
                    <LoanApplicationsFilterForm/>
                </Grid>
                <Grid item xs={6}>
                    <MonthlyVolumeOfNewDeals data={loansDetailsByStatus}
                                             currency={filters?.currency}
                                             label="Monthly volume of new deals"/>
                </Grid>
                <Grid item xs={6}>
                    <MonthlyVolumeOfNewDeals data={loansDetailsByStatus}
                                             cumulative
                                             currency={filters?.currency}
                                             label="Cumulative Monthly volume of new deals"/>
                </Grid>
                <Grid item xs={6}>
                    <MonthlyVolumeOfNewDealsByStatus
                                             data={loansDetailsByStatus}
                                             currency={filters?.currency}
                                             label="Monthly volume of new deals by application status"/>
                </Grid>
                <Grid item xs={6}>
                    <MonthlyVolumeOfNewDealsByStatus
                                             data={loansDetailsByStatus}
                                             cumulative
                                             currency={filters?.currency}
                                             label="Cumulative Monthly volume of new deals by application status"/>
                </Grid>
            </Grid>
        </Form>
    )
}