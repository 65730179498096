import axios from 'axios';
import queryString from 'query-string';
import {SELECT_ALL_VALUE} from "../common/constants";

export function getResourceUrl(resource, params, action) {
    let url = `/api/v1/${resource}`;
    if (params.id) {
        url += `/${encodeURIComponent(params.id)}`;
    }
    if (params.subres && params.subresId) {
        url += `/${params.subres}/${params.subresId}`
    }
    if(action) {
        url += `/${action}`;
    } else if (params.action) {
        url += `/${params.action}`;
    }
    if (params.queryStr) {
        url += `?${params.queryStr}`
    }
    return url;
}

const doAction = (authProvider) => (resource, params) => {
    const url = getResourceUrl(resource, params);
    return axios({
        url,
        method: params.method ? params.method : "PUT",
    }).then(function (response) {
        return {data: response.data}
    }).catch(function (error) {
        console.log("getAction: error loading", resource, params.id, params.action, error);
        throw error;
    });
}
const doBodyAction = (authProvider) => (resource, params) => {
    const url = getResourceUrl(resource, params);
    return axios({
        url,
        method: params.method ? params.method : "PUT",
        data: params.body,
        responseType: params.responseType ? params.responseType : undefined
    }).then(function (response) {
        return {data: response.data}
    }).catch(function (error) {
        console.log("getAction: error loading", resource, params.id, params.action, error);
        throw error;
    });
}
const doUpload = (authProvider) => (resource, params) => {
    const url = getResourceUrl(resource, params);
    return axios({
        url,
        method: params.method ? params.method : "POST",
        data: params.body,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(function (response) {
        return {data: response.data}
    }).catch(function (error) {
        console.log("doUpload error:", resource, params.id, params.action, error);
        throw error;
    });
}

const getList = (authProvider) => (resource, params) => {
    // console.log("getList", params)

    let queryParams = {};
    if (params.pagination) {
        queryParams.page = params.pagination.page - 1;
        queryParams.size = params.pagination.perPage;
    }
    if (params.sort) {
        queryParams.sort = params.sort.field + "," + params.sort.order;
    }
    let filter = ""
    if (params.filter) {
        for (const param in params.filter) {
            // console.log("param", param);
            // console.log("params.filter[param]", params.filter[param])
            if(Array.isArray(params.filter[param])) {
                for (const queryParam of params.filter[param]) {
                    filter += "&" + param + "=" + encodeURI(queryParam);
                }
            } else {
                filter += "&" + param + "=" + encodeURI(params.filter[param]);
            }
        }
    }
    const query = {
        resource: resource,
        ...queryParams
    };

    let method = params.meta?.method
    if (!method) {
        method = 'get'
    }

    let addedQueryParams = ""
    if (params.meta?.updateQueryParams && params.meta?.updateQueryParams instanceof Function) {
        addedQueryParams = params.meta?.updateQueryParams(queryParams.page)
    }

    const url = `/api/v1/${resource}?${queryString.stringify(query)}${filter}${addedQueryParams}`;
    // console.log("url", url)
    const cb = response => {
        if (params.meta?.transform && params.meta?.transform instanceof Function) {
            return params.meta.transform(response, queryParams.page);
        }

        if(Array.isArray(response.data)) {
            return {data: response.data, total: response.data.length}
        } else {
            if (resource.startsWith("dicts") && response.hasOwnProperty('data') && response.data.hasOwnProperty('data')) {
                // some dictionaries have "id" attribute but some only "code"
                response.data.data = response.data.data.map(
                    resource => {
                        const r = resource.id ? resource : {...resource, id: resource.code}
                        if (r.deletedDate) {
                            r.name += " (removed)"
                        }
                        return r;
                    }
                )
                //filter on client side
                if(params.filter) {
                    response.data.data = response.data.data.filter((obj) => {
                        for (const param in params.filter) {
                            // avoid using client filter for filter by 'code'
                            if (param === 'code')
                                return true;
                            if (param !== 'includeDeleted') {
                                if (obj[param] !== params.filter[param]) {
                                    return false;
                                }
                            }
                        }
                        return true;
                    })
                }
                // console.log("params", params)
                if (params.meta?.allowSelectAll) {
                    response.data.data.unshift({id: SELECT_ALL_VALUE, code: SELECT_ALL_VALUE, name: "Select All"})
                    // console.log("loaded", response.data.data)
                }
            }
            // console.log("dict", {...response.data})
            return {...response.data}
        }
    }
    const errFunc = error => {
        console.log(error);
        if (params?.meta?.errorHandler) {
            return params.meta.errorHandler(error)
        } else {
            throw new Error(
                error.response.data.errMessage
                    ? error.response.data.errMessage
                    : error.message
            );
        }
    }
    return axios({method, url}).then(cb).catch(errFunc);

}

const getMany = (authProvider) => (resource, params) => {
    if(resource.startsWith("dicts/")) {
        const result = new Promise((resolve, reject) => {
            getList(authProvider)(resource, params).then((result) => {
                const filtered = result.data.filter(rec => params.ids.includes(rec.code) )
                resolve({data: filtered})
            }).catch((err) => {
                reject(err)
            });

        })
        return result;
    } else {
        const body = params.ids;
        const rs = params.meta?.getManyResource || `${resource}/findByIDs` ;
        const url = `/api/v1/${rs}`;

        return axios.post(url, body, {
        }).then(response => {
            return {data: response.data}
        }).catch(error => {
            console.error("getMany error", error);
            throw error;
        });
    }
}

const getOne = (authProvider) => (resource, params) => {
    const url = `/api/v1/${resource}` + (params.id ? `/${params.id}` : "");
    return axios.get(url, {
    }).then(function (response) {
        if (resource.startsWith("dicts") && response.hasOwnProperty('data') && response.data.id === undefined) {
            response.data.id = response.data.code
        }
        return {data: response.data}
    }).catch(function (error) {
        console.log("getOne: error loading", resource, params.id, error);
        throw error;
    });
}

const create = (authProvider) => (resource, params) => {
    const body = {
        ...params.data
    };

    const url = getResourceUrl(resource, params);

    return axios.post(url, body, {
    }).then(function (response) {
        if (resource.startsWith("dicts") && response.hasOwnProperty('data') && response.data.id === undefined) {
            response.data.id = response.data.code
        }

        return {data: response.data}
    }).catch(function (error) {
        console.log(error);
        throw new Error(
            error.response.data.errMessage
                ? error.response.data.errMessage
                : error.message
        );
    });
}

const update = (authProvider) => (resource, params) => {

    // console.log("UPDATE DATAPROVIDER:", resource, params)

    const body = {
        ...params.data
    };

    const url = getResourceUrl(resource, params, params.meta);
    return axios.put(url, body, {
    }).then((response) => {
        if (resource.startsWith("dicts") && response.hasOwnProperty('data') && response.data.id === undefined) {
            response.data.id = response.data.code
        }

        return {data: response.data}
    }).catch((error) => {
        throw new Error(
            error.response.data.errMessage
                ? error.response.data.errMessage
                : error.message
        );
    });
}

const deleteResource = (authProvider) => (resource, params) => {

    const url = `/api/v1/${resource}/${params.id}`;

    return axios.delete(url, {
    }).then((response) => {
        return {data: true}
    }).catch((error) => {
        console.log("delete error:", error)
        throw new Error(
            error.response?.data?.errMessage
                ? error.response.data.errMessage
                : error.message
        );
    });
}

const deleteManyResources = (authProvider) => (resource, params) => {
    const query = {
        id: params.ids
    }

    const url = `/api/v1/${resource}?${queryString.stringify(query)}`;

    return axios.delete(url, {
    }).then((response) => {
        return {data: response.data};
    }).catch((error) => {
        console.log("delete error:", error)
        throw new Error(
            error.response?.data?.errMessage
                ? error.response.data.errMessage
                : error.message
        );
    });

}

export default (authProvider) => ({

    getList: getList(authProvider),

    getOne: getOne(authProvider),


    getMany: getMany(authProvider),

    getManyReference: getMany(authProvider),

    create: create(authProvider),

    update: update(authProvider),

    delete: deleteResource(authProvider),

    doAction: doAction(authProvider),

    doBodyAction: doBodyAction(authProvider),

    doUpload: doUpload(authProvider),

    deleteMany: deleteManyResources(authProvider)
});
