import {useEffect, useState} from "react";
import {useDataProvider, useNotify, useTheme} from "react-admin";
import Resources from "../../Resources";
import {LoanApplicationStatus} from "../dicts/LoanApplicationEnum";
import {formatAmount, onError} from "../../common/utils";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import {PieChart} from "@mui/x-charts/PieChart";
import {mangoFusionPalette} from "@mui/x-charts/colorPalettes";
import * as React from "react";

export const LoanApplicationsByStatus = (props) => {
    const [data, setData] = useState([])
    const [graphData, setGraphData] = useState([])
    const [totalRow, setTotalRow] = useState()
    const [percentColumnExist, setPercentColumnExist] = useState(false)
    //todo use loading
    const [loading, setLoading] = useState(false)
    const dataProvider = useDataProvider();
    const [theme] = useTheme();
    const notify = useNotify();
    const {filterValues} = props
    const {currency, createdDateFrom, createdDateTo} = filterValues || {}

    const toISODate = (d) => {
        return d?.toISOString ? d.toISOString().split("T")[0] : d
    }

    const loadData = () => {
        setLoading(true)
        dataProvider.doAction(Resources.DASHBOARD.LOAN_APPLICATIONS_SUMMARY_BY_STATUS.name, {
            method: 'GET',
            queryStr: `currency=${currency}&createdDateFrom=${toISODate(createdDateFrom)}&createdDateTo=${toISODate(createdDateTo)}`
        })
            .then(({data}) => {
                // console.log("data", data);
                let withoutTotal = data.data.filter(item => item.id !== 'TOTAL');
                let total = data.data.filter(item => item.id === 'TOTAL');
                if (total?.length) {
                    setTotalRow(total?.length ? total[0] : undefined);
                    const totalAmount = total[0].totalAmount
                    withoutTotal.forEach(e => {
                        e.amountPercent = Math.round(e.totalAmount/totalAmount*100)
                    })
                    setPercentColumnExist(true)
                }
                setData(withoutTotal)
                setGraphData(withoutTotal.map(
                    ({id, totalAmount, count, avgDealSize}) =>
                        ({id, value: count, label: LoanApplicationStatus[id]?.label}))
                )
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                onError(err, notify)
            });
    }

    useEffect(() => {
        if (filterValues)
            loadData()
    }, [filterValues]);

    const legendPlacement = {
        slotProps: {
            legend: {
                position: {
                    vertical: 'middle',
                    horizontal: 'right',
                },
                direction: 'column',
                itemGap: 2,
            },
        },
        margin: {
            top: 20,
            right: 100,
        },
    }

    return (
        <Grid container columnSpacing={1} rowSpacing={1} className={`dashboard-grid dashboard-grid-${theme}`}>
            <Grid item xs={8}>
                <div className="header">
                    <Typography variant="h12" sx={{width: "100%"}}>
                        Loan Applications Summary by Status
                    </Typography>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Loan Application Status</th>
                        <th>Total Amount of all Loan Applications</th>
                        {percentColumnExist && <th className="fmt-percent-th">%</th>}
                        <th>Number of Loan Applications</th>
                        <th>Average deal size</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.map((e, idx) => (
                            <tr key={idx}>
                                <td>{LoanApplicationStatus[e.id]?.label}</td>
                                <td className="fmt-number">{formatAmount(currency, e.totalAmount, {maximumFractionDigits: 0})}</td>
                                {percentColumnExist && <td className="fmt-percent">{e.amountPercent}%</td>}
                                <td className="fmt-number">{e.count}</td>
                                <td className="fmt-number">{formatAmount(currency, e.avgDealSize, {maximumFractionDigits: 0})}</td>
                            </tr>
                        )
                    )}
                    </tbody>
                    <tfoot>
                    {totalRow && <tr>
                        <td>{totalRow.id}</td>
                        <td className="fmt-number">{formatAmount(currency, totalRow.totalAmount, {maximumFractionDigits: 0})}</td>
                        {percentColumnExist && <td>-</td>}
                        <td className="fmt-number">{totalRow.count}</td>
                        <td className="fmt-number">{formatAmount(currency, totalRow.avgDealSize, {maximumFractionDigits: 0})}</td>
                    </tr>}
                    </tfoot>
                </table>
            </Grid>
            <Grid item xs={4}>
                <PieChart
                    series={[
                        {
                            data: graphData,
                        },
                    ]}
                    width={600}
                    // height={300}
                    colors={mangoFusionPalette}
                    {...legendPlacement}
                />
            </Grid>
        </Grid>
    )
}